var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('z-plate',{attrs:{"title":"查看评委账户","has-bottom-border":""},scopedSlots:_vm._u([{key:"titleRight",fn:function(){return [_c('el-button',{attrs:{"type":"primary","icon":"el-icon-circle-plus-outline"},on:{"click":_vm.handleAdd}},[_vm._v("增加评委")])]},proxy:true}])},[_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.tableLoading),expression:"tableLoading"}],staticClass:"z-table",staticStyle:{"width":"100%"},attrs:{"data":_vm.tableData}},[_c('el-table-column',{attrs:{"type":"index","label":"序号","width":"60"}}),_c('el-table-column',{attrs:{"prop":"userName","label":"评委姓名"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.isEdit)?_c('el-input',{staticStyle:{"width":"auto"},attrs:{"placeholder":"请输入评委姓名","maxlength":"30"},model:{value:(row.userName),callback:function ($$v) {_vm.$set(row, "userName", $$v)},expression:"row.userName"}}):_c('span',[_vm._v(_vm._s(row.userName))])]}}])}),_c('el-table-column',{attrs:{"prop":"loginName","label":"用户名"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.isEdit)?_c('el-input',{staticStyle:{"width":"auto"},attrs:{"placeholder":"请输入用户名","maxlength":"50"},model:{value:(row.loginName),callback:function ($$v) {_vm.$set(row, "loginName", $$v)},expression:"row.loginName"}}):_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v(_vm._s(row.loginName))])]}}])}),_c('el-table-column',{attrs:{"label":"操作","width":"270","fixed":"right"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
var $index = ref.$index;
return [(row.isEdit)?[_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.handleSave(row)}}},[_vm._v("保存")]),_c('el-button',{on:{"click":function($event){return _vm.handleCancel(row, $index)}}},[_vm._v("取消")])]:[_c('el-button',{attrs:{"icon":"el-icon-edit"},on:{"click":function($event){return _vm.handleEdit(row)}}}),_c('el-button',{attrs:{"icon":"el-icon-delete"},on:{"click":function($event){return _vm.handleDel(row.userId,$index)}}}),_c('el-button',{on:{"click":function($event){return _vm.resetPwd(row.userId)}}},[_vm._v("重置密码")])]]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }