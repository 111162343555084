<template>
  <z-plate title="查看评委账户" has-bottom-border>
    <!-- title 右侧 -->
    <template #titleRight>
      <el-button type="primary" icon="el-icon-circle-plus-outline" @click="handleAdd">增加评委</el-button>
    </template>

    <!-- 表格 -->
    <el-table class="z-table" :data="tableData" v-loading="tableLoading" style="width: 100%">
      <el-table-column type="index" label="序号" width="60"></el-table-column>

      <el-table-column prop="userName" label="评委姓名">
        <template slot-scope="{ row }">
          <el-input v-model="row.userName" placeholder="请输入评委姓名" maxlength="30" v-if="row.isEdit" style="width: auto" />

          <span v-else>{{row.userName}}</span>
        </template>
      </el-table-column>

      <el-table-column prop="loginName" label="用户名">
        <template slot-scope="{ row }">
          <el-input v-model="row.loginName" placeholder="请输入用户名" maxlength="50" v-if="row.isEdit" style="width: auto" />

          <span style="font-weight: bold" v-else>{{row.loginName}}</span>
        </template>
      </el-table-column>

      <el-table-column label="操作" width="270" fixed="right">
        <template slot-scope="{ row, $index }">
          <template v-if="row.isEdit">
            <el-button type="primary" @click="handleSave(row)">保存</el-button>
            <el-button @click="handleCancel(row, $index)">取消</el-button>
          </template>

          <template v-else>
            <el-button icon="el-icon-edit" @click="handleEdit(row)"></el-button>

            <el-button icon="el-icon-delete" @click="handleDel(row.userId,$index)"></el-button>

            <el-button @click="resetPwd(row.userId)">重置密码</el-button>
          </template>
        </template>
      </el-table-column>
    </el-table>

  </z-plate>
</template>

<script>
export default {
  data() {
    return {
    	isAdd: false,
    	editFlag: true,
    	activityid: sessionStorage.getItem("group_activityid"),
      tableData: [],
      tableLoading: false
    };
  },

  inject: ["Frame"],

  mounted() {
    this.init();
  },

  methods: {
  	resetPwd(userId){
  		this.$confirm("确定要重置吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
      	this.$post('/resetPingWeiPwdByUserId',{"userid": userId}).then(rep => {
	      	this.$message({
	          message: "重置成功，当前密码为：111111",
	          type: "success",
	          showClose: true
	        });
	      })
      });
  	},
    init() {
      this.tableLoading = true;
      this.$post('/queryPingWeiListByActivityId',{"activityid": this.activityid}).then(rep => {
      	this.tableLoading = false;
      	this.tableData = rep.content.tableData;
      })
    },
    handleAdd() {
    	if(this.isAdd){
    		this.$message({
          message: "请先保存，再进行添加",
          type: "info",
          showClose: true
        });
    	}else{
    		this.Frame.scrollToBottom();
	      this.tableData.push({
	        id: "",
	        userId: "",
	        userName: "",
	        loginName: "",
	        isEdit: true
	      });
	      this.isAdd = true;
    	}
    },
    handleEdit(row) {
      this.$set(row, "isEdit", true);
      this.isAdd = true;
    },
    handleDel(userId,index) {
      this.$confirm("确定删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
      	this.$post('/delPingWeiInfoByUserId',{"activityid": this.activityid, "userid": userId}).then(rep => {
	        //this.tableData.splice(index, 1);
	        this.init();
	        this.$message({
	          message: "删除成功",
	          type: "success",
	          showClose: true
	        });
       });
      });
    },
    handleSave(row) {
    	if(!row.userName){
    		this.$message({
		        message: "评委姓名不能为空",
		        type: "info",
		        showClose: true
		      });
    		return;
    	}
    	
    	if(!row.loginName){
    		this.$message({
		        message: "用户名不能为空",
		        type: "info",
		        showClose: true
		      });
    		return;
    	}
    	
    	if(this.editFlag){
    		this.editFlag = false;
    		row.activityid = this.activityid;
	      this.$post('/savePingWeiInfoByActivityId', row).then(rep => {
	      	this.editFlag = true;
	      	if(rep.resultCode == 0){
	      		this.isAdd = false;
	      		//row.isEdit = false;
	      		this.init();
	      		this.$message({
			        message: "保存成功，默认密码为 111111",
			        type: "success",
			        showClose: true
			      });
	      	}else{
	      		this.$message({
			        message: "用户名已存在",
			        type: "info",
			        showClose: true
			      });
	      	}
	      })
    	}
    },

    handleCancel(row, index) {
      //添加的
      if (row.id === "") {
        this.tableData.splice(index, 1);
      } else {
        //修改的
        row.isEdit = false;
      }
			this.isAdd = false;
      this.$message({
        message: "已取消",
        type: "info",
        showClose: true
      });
    }
  }
};
</script>

<style lang="scss" scoped>
</style>